
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import { ComplementedThread } from "@/entities/thread";
import { getThreadById, complementThreads } from "@/api/thread";
import {
  convertToDateFromUnixtimeSlash,
  convertToTimeFromUnixtime
} from "@/utils/date";
import MIcon from "@/components/MIcon.vue";
import MButton from "@/components/MButton.vue";
import MSMessagePanel from "@/components/student/MSMessagePanel.vue";

@Options({
  components: {
    MIcon,
    MButton,
    MSMessagePanel
  }
})
export default class ThreadPrint extends Vue {
  dataMaps: ComplementedThread[] = [];
  useMessagePanel = false;
  imageUrlsRequiringAltDisplay: string[] = [];

  get icon() {
    return require("@/assets/student-icon.svg");
  }

  toTimeString(unix: number): string {
    return `${convertToDateFromUnixtimeSlash(unix)} ${convertToTimeFromUnixtime(
      unix
    )}`;
  }

  isLongComment(comment: string): boolean {
    return comment.length + (comment.split("\n").length - 1) * 100 > 300;
  }

  print() {
    print();
  }

  setAltImage(url: string) {
    this.imageUrlsRequiringAltDisplay = [
      url,
      ...this.imageUrlsRequiringAltDisplay
    ];
  }

  async mounted() {
    const params = new URLSearchParams(location.search);
    const ids = params.get("id")?.split(",") ?? [];
    if (ids.length === 0) return;
    try {
      const threads = await getThreadById(ids);
      this.dataMaps = await complementThreads(threads);
      const title = this.dataMaps
        .map(d => d.threadMasterData?.name)
        .filter(_ => _)
        .filter((n, i, self) => self.indexOf(n) === i)
        .join("|");
      // 通常提出物が複数あっても提出物マスターは同じなので、すべて重複する
      document.title = title;

      // 対応する生徒が１人だけの場合、メッセージパネルを初期化
      const studentRefs = this.dataMaps
        .map(d => d.studentRef)
        .filter((ref, index, self) => self.indexOf(ref) === index);
      this.useMessagePanel = studentRefs.length === 1;
      if (this.useMessagePanel) {
        store.dispatch("messagePanel/changeMessageStream", studentRefs[0]);
      }
    } catch (e) {
      alert(`提出物取得中にエラーが発生しました\n\n${e.message}`);
    }
  }
}
