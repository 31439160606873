import {
  threadCollectionKey,
  Thread,
  ComplementedThread,
  ComplementedThreadData,
  convertToThreadData,
  ComplementedThreadPost
} from "@/entities/thread";
import { getValuesFromFileMetadata } from "@/entities/submission";
import { ThreadMaster, convertToThreadMaster } from "@/entities/thread_master";
import { Student, convertToStudent } from "@/entities/student";
import firebase from "firebase/app";
import mime from "mime";

export async function getThreadOfStudent(
  studentRef: firebase.firestore.DocumentReference,
  limit = 0
): Promise<Thread[]> {
  let query = studentRef
    .collection(threadCollectionKey)
    .orderBy("displayEndAt");
  if (limit > 0) {
    query = query.limit(limit);
  }
  const threadsSnapshot = await query.get();
  if (threadsSnapshot.empty) {
    return [];
  }
  return threadsSnapshot.docs.map(doc => ({
    data: convertToThreadData(doc.data()),
    ref: doc.ref
  }));
}

export async function getThreadById(threadDocIds: string[]): Promise<Thread[]> {
  /*
    .where(field, "in", list)
    の list を 11 件以上にすることが Firebase の仕様上できないので
    10 件ずつ分割して取得する。
  */
  const chunk = [];
  for (let i = 0, l = threadDocIds.length; i < l; i++) {
    if (i % 10 === 0) chunk.push([] as string[]);
    chunk[Math.floor(i / 10)].push(threadDocIds[i]);
  }
  const partialResults = await Promise.all(
    chunk.map(async c => {
      const snapshot = await firebase
        .firestore()
        .collectionGroup(threadCollectionKey)
        .where("uid", "in", c)
        .get();
      if (snapshot.empty) return [] as Thread[];
      return snapshot.docs.map(doc => {
        const thread = {
          ref: doc.ref,
          data: convertToThreadData(doc.data())
        };
        return thread as Thread;
      });
    })
  );
  const results = partialResults.flat();
  results.sort(
    (a, b) => threadDocIds.indexOf(a.ref.id) - threadDocIds.indexOf(b.ref.id)
  );
  return results;
}

export async function getThreadByThreadMaster(
  threadMasterRef: firebase.firestore.DocumentReference
): Promise<Thread[]> {
  const snapshot = await firebase
    .firestore()
    .collectionGroup(threadCollectionKey)
    .where("threadMasterRef", "==", threadMasterRef)
    .get();
  if (snapshot.empty) return [] as Thread[];
  const results = snapshot.docs.map(doc => {
    const thread = {
      ref: doc.ref,
      data: convertToThreadData(doc.data())
    };
    return thread as Thread;
  });
  return results;
}

/*
  thread にそれぞれ対応する
  threadMaster, student を辿って補完して返す。
*/
export async function complementThreads(
  threads: Thread[]
): Promise<ComplementedThread[]> {
  return new Promise((resolve, reject) => {
    // 同じ threadMaster を重複して取得しなくてよいよう、重複を省いたリストを作る
    const threadMastersToGet = threads
      .map(thread => thread.data.threadMasterRef)
      .filter((ref, index, self) => {
        return self.findIndex(m => m.id === ref.id) === index;
      });
    // student についても、重複を省いたリストを作る
    const studentsToGet = threads
      .map(thread => thread.ref.parent.parent)
      .filter((ref, index, self) => {
        return self.findIndex(m => m?.id === ref?.id) === index;
      });

    // 画像以外のファイルのメタデータを取得する
    const fileMetadataToGet = threads.map(s => {
      return Promise.all(
        s.data.posts.map(async ({ url }) => {
          if (!url) return {};
          const ref = await firebase.storage().refFromURL(url);
          return new Promise((resolve, _) => {
            ref
              .getMetadata()
              .then(resolve)
              .catch(() => {
                // ファイルが定期処理により削除されている場合
                resolve({
                  size: -1,
                  contentType: "_deleted"
                });
              });
          });
        })
      );
    });

    Promise.all([
      // threadMaster を取得する
      Promise.all(threadMastersToGet.map(ref => ref.get())),
      // student を取得する
      Promise.all(studentsToGet.map(ref => ref?.get())),
      // 画像以外のファイルのメタデータを取得する
      Promise.all(fileMetadataToGet),
      threads
    ])
      .then(results => {
        const threadMasters = results[0];
        const students = results[1];
        const fileMetaData = results[2];
        const dataMaps = results[3].map((thread, threadIndex) => {
          /*
              Student を整理。Submission があって Student がないことは
              通常考えられないのでビルドを通すための手続き
            */
          const studentDocId = thread.ref.parent?.parent?.id ?? "";
          const matchedStudent = students.find(
            student => student?.ref.id === studentDocId
          );
          const studentData = matchedStudent?.data();
          let student: Student | undefined;
          if (studentData && matchedStudent) {
            student = convertToStudent(studentData, matchedStudent.ref);
          }

          const matchedThreadMaster = threadMasters.find(
            m => m.ref.id === thread.data.threadMasterRef.id
          );
          const threadMasterData = matchedThreadMaster?.data();
          let threadMaster: ThreadMaster | undefined;
          if (threadMasterData && matchedThreadMaster) {
            threadMaster = convertToThreadMaster(
              threadMasterData,
              matchedThreadMaster.ref
            );
          }

          const ComplementedThreadData = {
            ...thread.data,
            posts: fileMetaData[threadIndex].map(
              (metadata: any, nodeIndex: number) => {
                const values = getValuesFromFileMetadata(
                  metadata.size,
                  metadata.contentType
                );
                return {
                  ...thread.data.posts[nodeIndex],
                  ...values,
                  contentTypeToDisplay:
                    values.contentIconType !== "file"
                      ? values.contentTypeToDisplay
                      : `${mime.getExtension(metadata.contentType)}ファイル` ??
                        values.contentTypeToDisplay
                } as ComplementedThreadPost;
              }
            )
          };

          const result = {
            ...thread,
            ref: thread.ref,
            data: ComplementedThreadData as ComplementedThreadData,
            threadMasterRef: threadMaster?.ref,
            threadMasterData: threadMaster?.data,
            studentRef: student?.ref,
            studentData: student?.data
          } as ComplementedThread;
          return result;
        });
        resolve(dataMaps);
      })
      .catch(reject);
  });
}
