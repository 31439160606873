import firebase from "firebase/app";

import { ThreadMasterData } from "./thread_master";
import { StudentData } from "./student";

export const threadCollectionKey = "threads";

export type Thread = {
  ref: firebase.firestore.DocumentReference;
  data: ThreadData;
};

/*
  スレッドを補完した状態の Thread
*/
export type ComplementedThread = {
  ref: firebase.firestore.DocumentReference;
  data: ComplementedThreadData;
  threadMasterRef?: firebase.firestore.DocumentReference;
  threadMasterData?: ThreadMasterData;
  studentRef?: firebase.firestore.DocumentReference;
  studentData?: StudentData;
};
export type ThreadPost = {
  type: "image" | "file" | "text";
  time: number;
  url: string;
  value: string;
};
export type ComplementedThreadPost = {
  type: "image" | "file" | "text";
  time: number;
  url: string;
  value: string;
  contentTypeToDisplay: string;
  contentIconType: string;
  sizeString: string;
};
export type ThreadData = {
  threadMasterRef: firebase.firestore.DocumentReference;
  posts: ThreadPost[];
  submittableFrom: number;
  displayEndAt: number;
  uid: string;
};

export type ComplementedThreadData = {
  threadMasterRef: firebase.firestore.DocumentReference;
  posts: ComplementedThreadPost[];
  submittableFrom: number;
  displayEndAt: number;
  uid: string;
};

export const convertToThreadData = (data: firebase.firestore.DocumentData) => {
  return {
    threadMasterRef: data.threadMasterRef,
    posts: data.posts,
    submittableFrom: data.submittableFrom,
    displayEndAt: data.displayEndAt,
    uid: data.uid
  } as ThreadData;
};

export const convertToThread = (
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Thread => {
  return {
    data: convertToThreadData(data),
    ref
  };
};
